
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { MeetingInterface } from '@/interface/BaseInterface'

  @Component({ components: {} })
  export default class MeetingDetailCard extends Vue {
    @Prop({ required: true, type: Object }) data!: MeetingInterface

    textarea = ''

    handleClose() {
      this.$emit('close')
    }
  }
