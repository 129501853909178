
  import { Component, Vue, Prop } from 'vue-property-decorator'
  import { MeetingInterface } from '@/interface/BaseInterface'

  @Component({ components: {} })
  export default class MeetingItem extends Vue {
    @Prop({ required: true, type: Object }) data!: MeetingInterface

    handleClick() {
      this.$emit('onClick', this.data)
    }
  }
