
  import { Component, Mixins } from 'vue-property-decorator'
  import MeetingApi from '../api/mettingApi'
  import getApi from '@/api'
  import { MeetingInterface } from '@/interface/BaseInterface'
  import ListMeetings from '../components/mettings/ListMeetings.vue'
  import PopupMixin from '@/mixins/popupMixin'
  import MemberFilter from '../components/member/MemberFilter.vue'

  const meetingApi: MeetingApi = getApi('meetingApi')

  @Component({ components: { ListMeetings, MemberFilter } })
  export default class MettingPage extends Mixins(PopupMixin) {
    data: MeetingInterface[] = []

    isLoading = false

    async init() {
      try {
        this.isLoading = true
        this.data = await meetingApi.list()
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    }

    handleCreate() {
      this.$router.push({ name: 'meetingCreate' })
    }

    initData() {
      meetingApi.init()
    }

    created() {
      this.init()
    }
  }
