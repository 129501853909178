
  import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
  import MeetingItem from './MeetingItem.vue'
  import { MeetingInterface } from '@/interface/BaseInterface'
  import { groupBy, size } from 'lodash'
  import MeetingDetailCard from './MeetingDetailCard.vue'

  @Component({ components: { MeetingItem, MeetingDetailCard } })
  export default class ListMettings extends Vue {
    @Prop({ required: true, type: Array }) data!: MeetingInterface[]

    selectedItem: MeetingInterface = {
      _id: '',
      name: '',
      startTime: new Date().getTime(),
      meetingId: ''
    }

    isFirstLoad = true

    get listMeetings() {
      return groupBy(this.data, value => {
        const date = new Date(value.startTime)
        return `${date.getFullYear()},${date.getMonth() + 1},${date.getDate()}`
      })
    }

    @Watch('data', { immediate: true }) watchData(new_: MeetingInterface[]) {
      if (size(new_) > 0 && this.isFirstLoad) {
        this.selectedItem = new_[0]
        this.isFirstLoad = false
      }
    }

    resetSelect() {
      this.selectedItem = {
        _id: '',
        name: '',
        startTime: new Date().getTime(),
        meetingId: ''
      }
    }

    handleItemClick(value: MeetingInterface) {
      this.selectedItem = value
    }
  }
